
import './Login.css';
//import logo from '../../logo.jpg';
import logo from '../../logo.jpg'
import axios from "axios";
import { useEffect, useState } from "react";
import { PasswordInput, TextInput, Button} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,useNavigate
} from "react-router-dom";
import { IconUser, IconPassword } from "@tabler/icons-react";



window.name = localStorage.getItem("user");
const base_url = process.env.REACT_APP_BASE_URL;
export default function Loginpage() {
  const [user, setUser] = useState(localStorage.getItem("user"))
  const [userRole, setRole] = useState(localStorage.getItem("userrole"))
  const navigate = useNavigate();
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [error, setErr] = useState(null);
  const refresh = () =>  ( setTimeout(function(){window.location.reload(true)}, 100));
  const handleSubmit = e => {
    // Prevent the default submit and page reload
    e.preventDefault()
    //Userfront.logout();
  //  console.log(email +'---'+ password)
    // Handle validations
    axios({
      method: "post",
      data: { "email": email, "password": password},
      url:base_url+"/admin/login"}).then((response) => { 
      console.log(response)
        localStorage.setItem("user", response.data.token);
        localStorage.setItem("userid", response.data.uuid);
        localStorage.setItem("userrole",response.data.role);
        localStorage.setItem("userName", 'Hi '+ response.data.firstname);
        localStorage.setItem("userEmail",  JSON.parse(response.config.data).email)
        localStorage.setItem("tokensendbird",  response.data?.response?.token)
        setUser(response.data.token);
        setRole(response.data.role)
        setErr(response.data.status);
        setTimeout(()=>{refresh()}, 100)
      }).catch(error => {  
    });   
  }

//console.log(user);

useEffect(() => {
 
  if(user && user !== 'undefined' && user !=='' && user !==null && userRole==='admin')  {
    navigate("/Dashboard")
    window.name = user;
}else if(userRole==='coach'){
  navigate("/Mygroups")
    window.name = user;
}
}, [user, refresh])

  const form = useForm({
    initialValues: { email: '', password: ''},
    validateInputOnBlur: true,
    validateInputOnChange: true,
    // functions will be used to validate values at corresponding key
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? setEmail(value) : 'Invalid email'),
      password: (value) => (value.length < 7 ? 'Password should be minimum 8 digit to Login' : setPassword(value)),
    },
})



  return(
    <div className='login-wrapper'>
      <div className='leftside'>
      <div className='logo'><img alt="Clova" src={logo}></img>
        <h3>Digital Health & Wellness Management</h3>
      </div>


    <form action="" id="login" method="post" onSubmit={handleSubmit}>
        
   
   {error === 400 ? (<div className="item error">Invalid Email and Password!</div>) : ''}

        <div className="item">
          

          <TextInput 
            id="email"
            
            onChange={e => setEmail(e.target.value)} placeholder="Email Address" {...form.getInputProps('email')}  rightSection={<IconUser size={16} />}
          />
        </div>
        <div className="item">
         
          <PasswordInput 
            id="password" autoComplete=''
            
            onChange={e => setPassword(e.target.value)} placeholder="Password"  {...form.getInputProps('password')}
          />
        </div>
        <div className="item">
          <Button type="submit" className="submit-btn">Log in</Button>
        </div>
      </form>

   

    </div>
    <div className='rightside'></div>
   
    </div>



  )
}







