
import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../header';
import './Coaches.css';
import { Box, Grid, TextInput, Checkbox, Group , Button, PasswordInput, NumberInput, Input} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { IconSearch, IconCalendar, IconArrowRight,IconPlus,  IconTrash, IconPencil } from "@tabler/icons-react";
import moment from 'moment';
import { useNavigate, Link } from 'react-router-dom';
import Loader from '../Loader/Loader';
import userImage from '../../images/user.svg';
import { IMaskInput } from 'react-imask';

function Coaches() {

  localStorage.setItem("DATE", (moment()).format('YYYY-MM-DD'));

const [appData, setAppoData] = useState([]);
const [loaded, dataLoading] = useState(true);
const user = localStorage.getItem("user").replace(/['"]+/g, '');
const [error, setError] = useState(false);
const role =localStorage.getItem("userrole");
const userName = localStorage.getItem("userName");

const[assinngGroup, setAssingGroup] = useState('');
const [gorupsData, setGroupData] = useState([]);
const [groupLists, setGoupsList] = useState([]);
const[memberList, setMemberList] = useState([]);
const [coachesList, setCoachesList] = useState([]);
const [selectedCoachGp, setSelectedCoachGp] = useState([]);
const [filterdCoach, setFilteredChoach] = new useState([]);

const [num1, setNum] = useState();
const base_url = process.env.REACT_APP_BASE_URL;
function getData(){
  if(role !=='admin'){ navigate('/Login'); }
  axios({ method: "POST", url:base_url+"/admin/get-all-coach", crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
      setGroupData(response.data.response); 
      
      dataLoading(true);
      if(response.data.status===200){setFilteredChoach(response.data.response);  }else{
        setGroupData('No data')
      }
  })
}

function getMembers(){
  axios({ method: "POST", url:base_url+"/admin/add-new-group-list", crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    setMemberList(response.data.response); 
  })
}

function getCoaches(e, i){
  setAssingGroup(e)
  setSelectedCoachGp(i)
  axios({ method: "POST", url:base_url+"/admin/get-all-groups", crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    if(response.data.response !== "No data found"){
    setCoachesList(response.data.response); 
    }

  })
 
}


const formEdit = useForm({
  initialValues: {
    added_on: '',
    contact_number: '',
    email: '',
    first_name: '',
    group_of_user: [],
    last_name: '',
    password_of_user:''
  }
});

function getEditCoach(e){
  setEditCoachMail(e)
  axios({ method: "POST", url:base_url+"/admin/edit-all-show-list", data:{"email": e}, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    setGoupsList(response.data.response?.[0]); 
    formEdit.setValues({
      added_on: response.data.response[0].added_on,
      contact_number: response.data.response[0].contact_number,
      email: response.data.response[0].email,
      first_name: response.data.response[0].first_name,
      group_of_user: [],
      last_name: response.data.response[0].last_name,
      password_of_user:response.data.response[0].password_of_user,
    });
   // console.log(response.data.response)
  })
 // console.log(assinngGroup)
 
}

const form = useForm({
  initialValues: {
    first_name: '',
    last_name: '',
    email: '',
    contact_number: '',
    password_of_user: ''
  },
  validate: {
    first_name: (value) => (value.length < 2 ? 'Invalid First Name' : null),
    last_name: (value) => (value.length < 2 ? 'Invalid Last Name' : null),
    contact_number: (value) => (value.length < 9 ? 'Invalid Contact Number' : null),
    email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    password_of_user: (value) => (value.length < 8 ? 'Password should be minimum 8 digit' : null),
    password_of_user_1: (value, values) => value !== values.password_of_user ? 'Passwords did not match' : null,
    
  },
});

const formAssign = useForm();



function handleSubmit(e) {
  dataLoading(false)
  axios({ method: "POST", url:base_url+"/admin/add-coach", data: e, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    //console.log(response.data.response); 
    if(response.data.response==="User has been registered and email sent"){ dataLoading(true); setActive(false); form.reset()  }else{
      dataLoading(true); setError('Fail to add Coach!')
    }
  })
}

function handleDelete(e){
  dataLoading(false)
  axios({ method: "POST", url:base_url+"/admin/remove-coach", data: {"email": e}, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
   // console.log(response.data.response); 
    if(response.data.response==="Coach removed successfully"){ dataLoading(true); setDelete(''); setSelectedUser('');  }else{
      dataLoading(true); setError('Fail to delete Coach!')
    }
  })
}

function handleAssign(e) {
  dataLoading(false)
const a = {
  "group_list":e.group_list,
  "email" :assinngGroup
}
  axios({ method: "POST", url:base_url+"/admin/assign-group-to-coach", data: a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    //console.log(response.data.response); 
    if(response.data.response==="Assigned the group to coach successfully"){ dataLoading(true); setCoach(false); formAssign.reset(); }else{
      dataLoading(true); setError('Fail to assign Group!')
    }
  }) 
}

function handleEdit(e) {
  dataLoading(false)
  let lc = (groupLists?.group_of_user)?.split(",").filter(n => n).length
  const obj = [];
 
 // console.log(lc)
  if(e.group_of_user.length === 0){
    (groupLists?.group_of_user)?.split(",").map(function(s) {
    //  console.log(s)
      obj.push(s)
    })
   }else if(e.group_of_user.length === lc){
    //console.log('s');
      obj.push()
   }else{
  ((groupLists?.group_of_user)?.split(",")).map(function(a) {
    if(e.group_of_user.length ){
    let matched = e.group_of_user.filter(b => a !== b);
    if (matched.length) { obj.push(a); } 
    }  
   })
  }
  
  
 const a = {
  "first_name":e.first_name,
  "last_name":e.last_name,
  "contact_number":e.contact_number,
  "email":e.email,
  "group_list":e.group_of_user,
  "group_of_user": String(obj)
}
//console.log(a)

   axios({ method: "POST", url:base_url+"/admin/edit-coach-list", data: a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    //console.log(response.data.response); 
    if(response.data.response==="Coach updated successfully"){ dataLoading(true); setEditCoach(false); formEdit.reset();  }else{
      dataLoading(true); setError('Your Changes have not been saved!')
    }
  })   
}

const [switchData, setSwithdata] = useState('userlist');
const [srch, search] = useState(false);


const navigate = useNavigate();
const logout = (e) => {
  /* let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
  keysToRemove.forEach(k =>
    localStorage.removeItem(k)) */
    localStorage.clear();
  navigate('/Login');
}

/* const logoutforadmin = (e) => {
  let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
  keysToRemove.forEach(k =>
    localStorage.removeItem(k))
  navigate('/Adminlogin');
} */

const [pagerVis, setPagerVis] = useState(true);

const [popopActive, setActive] = useState(false)
const [popopDelete, setDelete] = useState('')
const [assignCoach, setCoach] = useState(false)
const [userSelcted, setSelectedUser] = useState('')
const [editcoach, setEditCoach] = useState(false)
const [editCoachEmail, setEditCoachMail] = useState('')
const [searchActive, setSearchActive] = useState(false);
     useEffect(() => {
      if(searchActive===false){
      getData();
      }
      if(num1===null || num1==='' || num1==='undefined' || isNaN(num1)){
        setNum(1); 
        
      }
      if(popopActive===false && (popopDelete === null || popopDelete === '') && assignCoach===false && editcoach===false ){
        setError();
      }
  }, [popopActive, popopDelete, assignCoach, editcoach]);


  function filterByCoach (event){
    const query = event.target.value;
    var updatedList = [...gorupsData];
    updatedList = gorupsData.filter((item) => {
      return (item?.first_name+' '+item?.last_name).toLowerCase().indexOf(query.toLowerCase()) !== -1 ;
    });
    setFilteredChoach(updatedList);
  };
  function blurSerach(e){
    // console.log(e.target.value)
     if(e.target.value === '' || e.target.value ===null){setSearchActive(false)}
   }


  return (
    
    <div className="Dashboard" >
      

     


      <div className="page-wrpper"> 
      <Header />
    <div className='content-wrapper' >
        <div className='header-area'>
         
          <div className='header-top'>
            <div className='left-content' >
            <h1>Coaches List </h1>
            </div>

            
            <div className='right-content'>
            { role ==='admin' ? <Link className='adminlogin' to={'/Adminlogin'} target="_blank">Database Admin</Link> : ''}
            <span className='current-user'><img alt="user" src={ userImage } />
           <span className='user-details'><span className='username'>{ userName }</span> <span className='userrole'>{ role }</span></span>
           
           <span className='user-popup'><button onClick={ logout }>Logout</button></span>
           </span>
            </div> 
          </div>
          
          {switchData === 'userlist' ?
          <>
          
          <div className='header-left'>

           <Grid align="center" mb="md">
            <Grid.Col xs={8} sm={9}>
              <TextInput sx={{ flexBasis: '60%' }} onFocus={()=> setSearchActive(true)} onBlur={(value)=> blurSerach(value)} onChange={(value)=>filterByCoach(value)} placeholder="Search Coach by Name" icon={<IconSearch size={16} />}    />
            </Grid.Col></Grid>
        </div>

        <div className='header-right'>
        <div className='filter item' >
            <div className="filter-title" onClick={()=> (setActive(true), getMembers())}> + Add Coach </div>
        </div> 

      </div>
      
      </> : ''}
      </div>


      <div className='main-content groups-data'>
      
      
      {gorupsData === 'No data' ? '' : (

      <Box >
    {<DataTable
      noHeader
      records={filterdCoach}
      columns={[
        {cellsClassName: 'first-name', accessor: 'first_name', width: 100},
        {cellsClassName: 'last-name', accessor: 'last_name', width: 100},
        {cellsClassName: 'contact-number', accessor: 'contact_number', width: 150},
        {cellsClassName: 'email', width: 250, accessor: 'email'},
        {accessor: 'added_on', render:({added_on}) => (moment(added_on).format('DD MMM YYYY')), cellsClassName:'added-on', width: 150},
        {cellsClassName: 'groups', width: 200, accessor: 'group_of_user'},
        {accessor: 'members', cellsClassName:'ctas', width: 200,  render: ({ first_name, last_name, email, group_of_user}) => (
            <div className='cta-wrapper'>
              <span onClick={() => ( setCoach(true), getCoaches(email, group_of_user))} className='assign-cta'> <IconPlus></IconPlus> Assign Group</span>
              <span onClick={() => ( setEditCoach(true), getEditCoach(email))} className='edit-cta'> <IconPencil></IconPencil> Edit </span>
              <span onClick={() => (setDelete(email), setSelectedUser(first_name+" "+last_name)) } className='delete-cta'><IconTrash></IconTrash> Delete</span>
            </div> 
            )}
      ]}

    />}

{ pagerVis === true  ? ( <>
<div className="mantine-ooem79 pager">
  <div className="mantine-Group-root mantine-kn8vaf">
    {/* <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-4clav0" onClick={()=> { pager(-1) }} data-disbaled={num1===1 ? 'disabled' : 'visible'}  type="button" ><svg width="calc(1.625rem / 2)" height="calc(1.625rem / 2)" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="mantine-2jxpqh"><path d="M7.219 8l3.3 3.3-.943.943L5.333 8l4.243-4.243.943.943-3.3 3.3z" fill="currentColor"></path></svg></button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg" onClick={()=> {pager(-1)}} data-hidden={num1===1 ? 'disabled' : 'visible'} type="button" >{num1-1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg active" type="button" >{num1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg" onClick={()=> {pager(1)}} data-hidden={num1===a ? 'disabled' : 'visible'}  type="button" >{num1+1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-4clav0" onClick={()=> {pager(1) }} data-disbaled={num1===a ? 'disabled' : 'visible'} type="button" ><svg width="calc(1.625rem / 2)" height="calc(1.625rem / 2)" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="mantine-2jxpqh"><path d="M8.781 8l-3.3-3.3.943-.943L10.667 8l-4.243 4.243-.943-.943 3.3-3.3z" fill="currentColor"></path></svg></button>
 */}    </div>
    </div>
</>) :''}
</Box>
  )}

  
{loaded === false ? <div className='global-loader'><Loader /></div> : '' }

{ popopActive === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group assign-group add-coach'>
  <span className='close' onClick={()=> setActive(false)}></span>
  <div className='popup-titlebar'>
      <h2>Add New Coach</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={form.onSubmit((values) => ( handleSubmit(values)  ))}>
        <TextInput withAsterisk label="" required placeholder="First Name" {...form.getInputProps('first_name')}/>
        <TextInput withAsterisk label="" required placeholder="Last Name" {...form.getInputProps('last_name')} />
        <TextInput withAsterisk label="" required placeholder="email" {...form.getInputProps('email')} />
        <TextInput component={IMaskInput} mask="0000000000" required placeholder="Contact Number" {...form.getInputProps('contact_number')} />
        <PasswordInput withAsterisk label="" required placeholder="Password" {...form.getInputProps('password_of_user')} />
        <PasswordInput withAsterisk label="" required placeholder="Confirm Password" {...form.getInputProps('password_of_user_1')} />
        <Group justify="flex-end" mt="md">
          <Button type="submit">Submit</Button>
        </Group>
      </form>


</div>
</>
):''}

{ popopDelete !== null && popopDelete !== '' ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group delete-group'>
  <span className='close' onClick={()=> setDelete('')}></span>
  <div className='popup-titlebar'>
      <h2>Delete</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
    <div className='popup-content'>
      <p>Are you sure you want to Remove Coach { userSelcted }</p>
      <p>Once you Remove Coach { userSelcted }, all the data will be erased permanently.</p>
    </div>

        <Group justify="flex-end" mt="md">
          <Button className='cancel-cta' onClick={()=> setDelete('')}>Cancel</Button>
          <Button  onClick={()=> handleDelete(popopDelete)}>Yes</Button>
        </Group>
 


</div>
</>
):''}

{ assignCoach === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group assign-coach'>
  <span className='close' onClick={()=> setCoach(false)}></span>
  <div className='popup-titlebar'>
      <h2>Groups</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formAssign.onSubmit((values) => (handleAssign(values) ))}>
        
<Checkbox.Group 
      label=""
      description="" 
      withAsterisk {...formAssign.getInputProps('group_list')}
    >
      <Group>
        { coachesList?.map((i, index)=> (
           <>{selectedCoachGp?.includes(i) ?'' : <Checkbox key={index} value={i} label={i} /> }</>)
        )}
        
      </Group>
      
    </Checkbox.Group>


{ selectedCoachGp?.split(",").filter(n => n).length !== coachesList?.length ? <>
        <Group justify="flex-end" mt="md">
          <Button type="submit">Assign Group</Button>
        </Group>
        </> : <>
        <p style={{ 'textAlign' : 'center' }}> This coach assigned all Groups or no Group is available! </p>
        <Group justify="flex-end" mt="md">
          <Button disabled type="submit">Assign Group</Button>
        </Group>
        </>}
      </form>


</div>
</>
):''}

{ editcoach === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group edit edit-coach'>
  <span className='close' onClick={()=> (setEditCoach(false), formEdit.reset())}></span>
  <div className='popup-titlebar'>
      <h2>Edit</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formEdit.onSubmit((values) => (handleEdit(values) ))}>     
        <TextInput  label="First Name" required placeholder="First Name" {...formEdit.getInputProps('first_name')}/>
        <TextInput  label="Last Name" required placeholder="Last Name" {...formEdit.getInputProps('last_name')} />
        <TextInput  label="Email" disabled placeholder="email" {...formEdit.getInputProps('email')} />
        <TextInput  label="Contact Number" required placeholder="Contact Number" {...formEdit.getInputProps('contact_number')} />

        <Checkbox.Group className='members-list'
      label="Groups"
      description="" 
       {...formEdit.getInputProps('group_of_user')} >
      <Group>
        
        {groupLists?.group_of_user ? ((groupLists?.group_of_user).split(",")).map((i, index)=> 
           <Checkbox key={index} value={i} label={i} />
        ) : ''}
      </Group>
    </Checkbox.Group>


        <Group justify="flex-end" mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>


</div>
</>
):''}


</div>

    </div>

</div>

</div>

    
  );

}





export default Coaches;
