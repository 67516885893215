import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../header';
import './Mygroupsmembers.css';
import { Box, Grid, TextInput, Checkbox, Group , Button} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { IconSearch, IconCalendar, IconArrowRight, IconPlus, IconPencil, IconTrash, IconMessageCircle2Filled } from "@tabler/icons-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Link,  useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import dayjs from 'dayjs';
import userImage from '../../images/user.svg';
import SendBird from 'sendbird';


function Mygroupsmembers() {

  localStorage.setItem("DATE", (moment()).format('YYYY-MM-DD'));

const [loaded, dataLoading] = useState(false);

const user = localStorage.getItem("user").replace(/['"]+/g, '');
const role =localStorage.getItem("userrole");
const userName = localStorage.getItem("userName");
const memberGp = localStorage.getItem('userGroup');

const base_url = process.env.REACT_APP_BASE_URL;
const [filterdGroup, setFilteredGroup] = new useState([]);
const [selectedGroup, setSelectedGp] = useState([]);
const [gname, setgname] = useState();


const username = localStorage.getItem("nickname");
const coachId = localStorage.getItem("userid");
const coachName = userName?.replace(/Hi /g, '');
const userId = role === 'coach'? coachId :  '8sp5j4rp'; //'8sp5j4rp';
const userNames = role === 'coach'? coachName :  username; //'Clova Health';//'Clova Health';
const APP_ID = 'B34E4427-F748-41F9-8E77-CBE60E474837'; // Replace with your Sendbird Application ID
const sessionToken = localStorage.getItem("tokensendbird");


function openGoupList(e){
  setgname(e)
  axios({ method: "POST", url:base_url+"/admin/get-all-member", data:{"group_name": [e]},  crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    if(response.data.response !== 'No data found'){setSelectedGp(response.data.response?.sort((a, b) => a.firstname > b.firstname ? 1 : -1,)); setFilteredGroup(selectedGroup.sort((a, b) => b.created_at > a.created_at ? 1 : -1,));  dataLoading(true); 
      
    }
  })
}

const navigate = useNavigate();
const logout = (e) => {
  /* let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
  keysToRemove.forEach(k =>
    localStorage.removeItem(k)) */
    localStorage.clear();
  navigate('/Login');
}

/* const logoutforadmin = (e) => {
  let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
  keysToRemove.forEach(k =>
    localStorage.removeItem(k))
  navigate('/Adminlogin');
} */

const [searchActive, setSearchActive] = useState(false);

  function filterBySearch (event) {
      const query = (event.target.value).toLowerCase();
      var updatedList = [...selectedGroup];
      updatedList = selectedGroup.filter((item) => {
        return item?.firstname?.toLowerCase().indexOf(query) !== -1 || item?.lastname?.toLowerCase().indexOf(query) !== -1 || item?.mobile_number?.toLowerCase().indexOf(query) !== -1 || item?.email?.toLowerCase().indexOf(query) !== -1 || item?.country_code?.toLowerCase().indexOf(query) !== -1;
      });
      //console.log(updatedList)
      setFilteredGroup(updatedList);
    };
  
  function blurSerach(e){
    if(e.target.value === '' || e.target.value ===null){setSearchActive(false)}
  }
  
  const[tempSession, setTempSes] = useState();
  async function fetchNewSessionToken(userId) {
    //console.log(userId)
      const ac = {"user_id" : userId}
    axios({ method: "POST", url:base_url+"/admin/exercise/create_sendbird_access_token", data: ac,  crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`}, 'Content-Type': 'application/x-www-form-urlencoded'}).then((response) => {
        //setmermberCoach(response.data);
        //console.log(response)
        if(response.data.token){
          localStorage.setItem("tokensendbird", response.data.token)
          setTempSes(response.data.token)
        };
      
       
      })
    } 

function setUnreadVal(){
  const membs =  filterdGroup.map((val)=>{
    var uc = '';
     arry.map((item)=> {
        if(item.id === val.uuid){  uc =  item.count  }
      })
      return {...val, uc}   
    })

    const membsSearched =  selectedGroup.map((val)=>{
      var uc = '';
       arry.map((item)=> {
          if(item.id === val.uuid){  uc =  item.count  }
        })
        return {...val, uc}   
      })

    if(arry.length === 0){setMsgLoading(false)}
    setFilteredGroup(membs)
    setSelectedGp(membsSearched)
      //console.log(membs)
}


  const [unreadChannels, setUnreadChannels] = useState([]);
  const [msgloading, setMsgLoading] = useState(true);
  const arry = [];

  useEffect(() => {
    if(loaded === false){
        openGoupList(memberGp);   
    } 
    if(searchActive===false){
      openGoupList(memberGp);
      }  



      const tokenGet = localStorage.getItem("tokensendbird")
      const sb = new SendBird({ appId: APP_ID });
      sb.connect(userId, tokenGet, (user, error) => {
  
        if (error) {
          fetchNewSessionToken(userId)
          setTimeout(()=> {console.error('SendBird connection error:', error)}, 5000);
          return;
        } else {
         
          const query = sb.GroupChannel.createMyGroupChannelListQuery();
    
          query.includeEmpty = false;
          query.limit = 20; // Fetch 20 channels at a time
          query.unreadChannelFilter = 'unread_message';
      
          if (query.hasNext) {
            query.next((channelList, error) => {
              if (error) {
                console.error('Error fetching channels:', error);
                return;
              }
              // Filter channels with unread messages
              const channelsWithUnreadMessages = channelList.filter(channel => channel.unreadMessageCount > 0);
              setUnreadChannels(channelsWithUnreadMessages);
             // console.log(channelsWithUnreadMessages)
              if(loaded === true && channelsWithUnreadMessages.length > 0 && msgloading === true ){
                  
                
                Object.entries(unreadChannels).map((obj, i)  => {
                  obj[1].members.filter(usr => usr.userId !== userId).map((o, ii) => {
                    arry.push( {'id': o.userId, 'count' : obj[1].unreadMessageCount})
                  })
                  return {arry}
              })

              setUnreadVal()

              } else{
                setMsgLoading(true)
              }
            });
          }
  
          return () => {

            
            if (sb) {
              sb.disconnect();
            }

          }
        }
              
      });



      


    }, [loaded, memberGp, msgloading, tempSession ]);










  return (
    
    <div className="Dashboard" >
      <div className="page-wrpper"> 
      <Header />
      <div className='content-wrapper' >
        <div className='header-area'>
         
          <div className='header-top'>
            <div className='left-content' >
            <h1>Member List ({gname})</h1>
            </div>

            
            <div className='right-content'>
            { role ==='admin' ? <Link className='adminlogin' to={'/Adminlogin'} target="_blank">Database Admin</Link> : ''}
            <span className='current-user'><img alt="user" src={ userImage } />
           <span className='user-details'><span className='username'>{ userName }</span> <span className='userrole'>{ role }</span></span>
           
           <span className='user-popup'><button onClick={ logout }>Logout</button></span>
           </span>
            </div> 
          </div>
          
 
          
          <div className='header-left'>

           <Grid align="center" mb="md">
            <Grid.Col xs={8} sm={9}>
              <TextInput sx={{ flexBasis: '60%' }} onFocus={()=> setSearchActive(true)} onBlur={(value)=> blurSerach(value)} onChange={(value)=> filterBySearch(value)} placeholder="Search Members by First Name, Last Name, Conatct No. or Email" icon={<IconSearch size={16} />}    />
            </Grid.Col></Grid>
           
        </div> 

        <div className='header-right'>
        

      </div>

      </div>
      <div className='main-content groups-data'>
      


        <Box>
          <DataTable className="memers-table"
      noHeader
      records={filterdGroup}
      columns={[
        { accessor: 'uc', cellsClassName: 'msg-unread', width: 10, render: ({uc}) =>  (<div className={uc>0 ? 'visible' : 'hidden'} ><span className='count'>{uc}</span> <IconMessageCircle2Filled /></div>)},
        { accessor: 'firstname', cellsClassName: 'first-name', width: 90},
        {accessor: 'lastname', cellsClassName:'last-name', width: 90,},
        { accessor: 'mobile', render: ({ country_code, mobile_number }) => `${country_code} ${mobile_number}`, cellsClassName:'mobile', width: 150},
        {accessor: 'email', cellsClassName:'email', width: 200},
        { accessor: 'dob', render: ({ dob }) => moment(dob).format("DD MMM YYYY"),  cellsClassName:'dob', width: 120},
        {accessor: 'gender', cellsClassName:'gender', width: 80},
        {accessor: 'height', cellsClassName:'height', width: 75},
        {accessor: 'weight', cellsClassName:'weight', width: 75},
        {accessor: 'goal', cellsClassName:'goal', width: 70},
        {accessor: 'group_name', cellsClassName:'group-name', width: 120},        
        {accessor: 'created', render: ({ created_at }) => dayjs.unix(created_at).format("DD MMM YYYY"), cellsClassName:'onboarding',width: 100 },
        {accessor: 'uuid', cellsClassName:'user-link', width: 200,  render: ({ uuid,  dob, group_name}) => (<div className='cta-wrapper'><Link to={'/user?user='+uuid}  ><IconArrowRight /></Link></div> )},

      ]}

    />
        </Box>

  





{loaded === false ? <div className='global-loader'><Loader /></div> : '' }





</div>

    </div>

</div>


</div>

    
  );

}





export default Mygroupsmembers;
