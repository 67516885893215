
import { useEffect, useState } from 'react';
import Header from '../../header';
import './Useredit.css';
import { Box, Grid, Checkbox, TextInput, FileInput, Button } from '@mantine/core';
import { IconSearch, IconFilter, IconCalendar, IconArrowRight } from "@tabler/icons-react";
import Userfront from "@userfront/react";
import axios from 'axios';
import { json, Link, Navigate, useNavigate  } from 'react-router-dom';
import { useForm, matches } from '@mantine/form';
import moment from 'moment';
import Loader from '../Loader/Loader';


const base_url = process.env.REACT_APP_BASE_URL;
function Useredit() {

 var user = localStorage.getItem("user").replace(/['"]+/g, '');
 var uuid = localStorage.getItem("userid").replace(/['"]+/g, '');
 var date = moment().format('YYYY-MM-DD').replace(/['"]+/g, '');
  const [user_firstname, username] = useState();
  const [user_lastname, userlast] = useState();
  const [userEmail, useremail] = useState();
  const [password, userpass] = useState();
  const [contact, usercontact] = useState();

  const userload = {
    "uuid": uuid
}

  async function getData(){
    const  response = await axios({ method: "post", url:base_url+"/admin/get-staff-members-by-uuid", data: userload, 'Content-Type': 'application/json', headers: {"Authorization" : `Bearer ${user}`} })
    username(response.data.response[0].firstname);
    userlast(response.data.response[0].firstname);
    usercontact(response.data.response[0].mobile_number);
    useremail(response.data.response[0].email);
} 


const [checkinit, setinit] = useState('0');

useEffect(() => {
  getData();
  let ufn = JSON.stringify(user_firstname);
  let uln = JSON.stringify(user_lastname);
  let ueml = JSON.stringify(userEmail);
  let ucn = JSON.stringify(contact);
 // console.log(a)
  if( ufn=='' || ufn == null) {} else{
    
    if(checkinit==='0'){
      setinit('1');
      form.setValues({ uf: `${ufn.replace(/['"]+/g, '')}`, ul: `${uln.replace(/['"]+/g, '')}`,ue: `${ueml.replace(/['"]+/g, '')}`, cnt: `${ucn.replace(/['"]+/g, '')}`})
    }
  }
}, [getData]);



const [formMessage, setMsg] = useState();

  /*----- Edit current user --------*/
  const form = useForm({
    initialValues: {uf : '', ul:'', ue:'', pwd:'', cnt:''},
    validateInputOnBlur: true,
    validateInputOnChange: true,
    // functions will be used to validate values at corresponding key
    validate: {
      uf: (value) => (value.length < 1 ? 'First Name require' : username(value)),
      ul: (value) => (value.length < 1 ? 'Last Name require' : userlast(value)),
      cnt:  matches(/^([0-9]{10})$/, 'Enter a valid Contact Number'),
      ue: (value) => (/^\S+@\S+$/.test(value) ? useremail(value) : 'Invalid email'),
      pwd: (value) => (value.length < 7 ? 'Password should be minimum 8 digit to Login' : userpass(value)),
    },
    
})

const [newuserLoader1, setNULoader1] = useState('false');
async function formValide(e){
  e.preventDefault()
  form.validate();
  if(form.isValid() === true){
    setNULoader1(true);
    const newUser = {
      "uuid" : uuid,
      "firstname":form.values.uf,
      "lastname":form.values.ul,
      "country_code":"91",
      "mobile_number":form.values.cnt,
      "email":form.values.ue,
      "password":form.values.pwd,
     // "role": checked
    }
    console.log(newUser)
    const   response = await axios({ method: "post", url:base_url+"/admin/update-admin-user", data: newUser, 'Content-Type': 'application/json', headers: {"Authorization" : `Bearer ${user}`} })
    console.log(response)
    if(response.statusText === 'OK'){
      setNULoader1(false);
      setMsg(response.data.response);
      setpopup(false);
    }
    }

}


/*----- Add new user --------*/

const [new_firstname, newname] = useState();
const [new_lastname, newlast] = useState();
const [newEmail, newemail] = useState();
const [newContact, newcontact] = useState();
const [newPassword, newpassword] = useState();
const [checked, setChecked] = useState('staff');

const formSec = useForm({
  initialValues: {new_firstname:'', new_lastname:'', newEmail: '', newContact:'', newPassword: '', isAdmin: false},
  validateInputOnBlur: true,
  validateInputOnChange: true,
  // functions will be used to validate values at corresponding key
  validate: {
    new_firstname: (value) => (value.length < 1 ? 'First Name require' : newname(value)),
    new_lastname: (value) => (value.length < 1 ? 'Last Name require' : newlast(value)),
    newContact: matches(/^([0-9]{10})$/, 'Enter a valid Contact Number'),
    newEmail: (value) => (/^\S+@\S+$/.test(value) ? newemail(value) : 'Invalid email'),
    newPassword: (value) => (value.length < 7 ? 'Password should be minimum 8 digit to Login' : newpassword(value)),
  },
})

const onadminselect = (e) => {
    if(e===true){setChecked('admin')}else{setChecked('staff')}
}
const [newuserLoader, setNULoader] = useState('false');
async function formValideSec(e){
  
  e.preventDefault()
  formSec.validate();
  if(formSec.isValid() === true){
  setNULoader(true);
  const newUser = {
    "firstname":formSec.values.new_firstname,
    "lastname":formSec.values.new_lastname,
    "country_code":"91",
    "mobile_number":formSec.values.newContact,
    "email":formSec.values.newEmail,
    "password":formSec.values.newPassword,
    "role": checked
  }
  const   response = await axios({ method: "post", url:"https://iwellapi.hureka.com/api/admin/admin-register", data: newUser, 'Content-Type': 'application/json', headers: {"Authorization" : `Bearer ${user}`} })
  console.log(response)
  if(response.statusText === 'OK'){
    setNULoader(false);
    setMsg(response.data.response);
    setpopup(false);
  }
  }
 
}


  const [popopActive, setpopup] = useState(false);

  const navigate = useNavigate();
  const logout = (e) => {
    localStorage.removeItem('user');
    navigate('/Login');
  }
 




  return (
    
    <div className="Useredit" >

      
      <div className="page-wrpper"> 
      <Header />
    <div className='content-wrapper'>
        <div className='header-area'>
         
          <div className='header-top'>
            <div className='left-content' >
           
            </div>
            <div className='right-content'>
            <span className='current-user'><img src={ Userfront.user.image } />
           </span>
              <button onClick={ logout }>Logout</button>
            </div>
          </div>

      </div>

      <div className='main-content'>

      <div className='sidebar'>
        <div className='user-pic'></div>
        <div className='user-pic-update'>
            <button>Edit</button>
            <button>Remove</button>
        </div>
        <button onClick={(e) => setpopup(true) }>Add New Member</button>
      </div>
    
      <main>
      <h2>{ user_firstname} {user_lastname}</h2>

      {!formMessage == '' ? (<div className='msg-wrapper'>{formMessage}</div>) :''}
      <form onSubmit={formValide} >
      <Box>
      { newuserLoader1 === true ? <Loader></Loader> : ''}
        <div className='form-item'>
          <TextInput label="First Name :" value={ user_firstname }  onChange={e => username(e.target.value)}  {...form.getInputProps('uf')}  />
        </div>
        <div className='form-item'>
          <TextInput label="Last Name :" value={ user_lastname }  onChange={e => userlast(e.target.value)}  {...form.getInputProps('ul')} />
        </div>
        <div className='form-item'>
          <TextInput label="Email :" value={ userEmail } onChange={e => useremail(e.target.value)}  {...form.getInputProps('ue')}   />
        </div>
      <div className='form-item'>
          <TextInput label="Password :" value={ password } onChange={e => userpass(e.target.value)}  {...form.getInputProps('pwd')}  />
        </div>
        <div className='form-item'>
          <TextInput label="Contact Number :" value={ contact } onChange={e => usercontact(e.target.value)}  {...form.getInputProps('cnt')}   />
        </div>
      <Button type='submit' >Submit</Button>
      </Box>
      </form>
      </main>

      { popopActive === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup new-member'>
  <div className='popup-titlebar'>
    <h3>Add New Member</h3>
    <span className='close' onClick={(e)=>{ setpopup(false) }}></span>
  </div>
  <form onSubmit={formValideSec}>
  <Box>
        { newuserLoader === true ? <Loader></Loader> : ''}
        <div className='form-item'>
          <TextInput label="First Name :" value={ new_firstname }  onChange={e => newname(e.target.value)}  {...formSec.getInputProps('new_firstname')}  />
        </div>
        <div className='form-item'>
          <TextInput label="Last Name :" value={ new_lastname } onChange={e => newlast(e.target.value)}  {...formSec.getInputProps('new_lastname')}  />
        </div>
        <div className='form-item'>
          <TextInput label="Email :" value={ newEmail } onChange={e => newemail(e.target.value)}  {...formSec.getInputProps('newEmail')}    />
        </div>
        <div className='form-item'>
          <TextInput label="Password :" value={ newPassword } onChange={e => newpassword(e.target.value)}  {...formSec.getInputProps('newPassword')}  />
        </div>
        <div className='form-item'>
          <TextInput label="Contact Number :" value={ newContact }  onChange={e => newcontact(e.target.value)}  {...formSec.getInputProps('newContact')}  />
        </div>
        <div className='form-item'>
        <Checkbox  mt="md" label="is Admin User!" onClick ={e => {onadminselect( e.currentTarget.checked )}}
          {...formSec.getInputProps('isAdmin', { type: 'checkbox' })}
        />
        </div>
      <Button  type='submit' >Submit</Button>
      </Box></form>
</div>
</>
):''}   

      
    </div>
    </div>
</div> 
</div>

    
  );

}

export default Useredit;
