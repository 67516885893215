
import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../header';
import './Members.css';
import { Box, Grid, Checkbox, TextInput} from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { useDebouncedValue } from '@mantine/hooks';
import { IconSearch, IconFilter, IconCalendar, IconArrowRight, IconBucket, IconClearAll, IconTrash } from "@tabler/icons-react";
import Userfront from "@userfront/react";
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';




function Members() {

const [profileData, setProfileData] = useState([]);
const [appData, setAppoData] = useState([]);
const [loaded, dataLoading] = useState(false);
const user = localStorage.getItem("user").replace(/['"]+/g, '');
const role = localStorage.getItem("userrole");
let now = dayjs();

const [startD, setStartD] = useState((moment(now.format("YYYY-MM-DD")).subtract(5, 'month').startOf('month').format('YYYY-MM-DD')))
const [endD, setEndD] = useState((moment(now.format("YYYY-MM-DD")).endOf('month').format('YYYY-MM-DD')))
//console.log(startD);


const userApp = {
  "start_date":startD,
  "end_date":endD
}
const base_url = process.env.REACT_APP_BASE_URL;
function getData(){
     axios({
       method: "GET",
       url:base_url+"/admin/get-staff-members",
       crossDomain: true,
       headers: {
        "Authorization" : `Bearer ${ user }`},
     })
     .then((response) => { setProfileData(response.data.response); dataLoading(true);

    }) ///api/booking/get-appointments
}



//console.log(neAppdata)

  const PAGE_SIZE = 15;
  const [page, setPage] = useState(1);
  //const initialRecords = profileData.slice(0, PAGE_SIZE);
  const [records, setRecords] = useState(profileData.slice(0, PAGE_SIZE));
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const [startDate, setStartDate] = useState();
  const [today, setToday] = useState('');


     useEffect(() => {
      
     
      if(loaded===false) { getData();  }
   
   
      const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
      setRecords( 
      profileData.filter(({ firstname, lastname, mobile_number,  email, created_at }) => {
        //console.log(gender.toLowerCase());
        
            if(startDate){
          if(dayjs.unix(created_at).format("MM-DD-YYYY")!==moment(startDate).format("MM-DD-YYYY")) {
            //console.log(moment(startDate).format("MM-DD-YYYY")+'---'+dayjs.unix(created_at).format("MM-DD-YYYY"));
            return false; }
          }
          
        if (
          debouncedQuery !== '' &&
          !`${firstname} ${lastname} ${mobile_number} ${email}`
            .toLowerCase()
            .includes(debouncedQuery.trim().toLowerCase())
        ) { return false; }

   
       // setCount(records.length);
        return true;
        
       

      }).slice(from, to)
      )
      //console.log(listCount);

      
  
    
    
  }, [ debouncedQuery, getData ]);

//
  const [isActive] = useState("");
  const toggleClass = (e) => {
    if(e.target.getAttribute("status")==='current'){
    e.target.removeAttribute("status", 'current');
    }else{
    e.target.setAttribute("status", 'current');
    }
  };


  const navigate = useNavigate();
  const logout = (e) => {
    /* let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
    keysToRemove.forEach(k =>
      localStorage.removeItem(k)) */
      localStorage.clear();
    navigate('/Login');
  }

/*   const logoutforadmin = (e) => {
    let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
    keysToRemove.forEach(k =>
      localStorage.removeItem(k))
    navigate('/Adminlogin');
  } */

 const refresh = () =>  ( setTimeout(function(){window.location.reload(true)}, 500));

 const [popopActive, setpopup] = useState(false);
const [selectedUser, setSuser] = useState('');
const [setName, setname] = useState('');
const [setLastname, setlastname] = useState('');
async function handleSubmit (userId){
  setpopup(true)
 }

 const [delLoad, setDeLoad] = useState(false);
 const [msg, setMsg] = useState();
 async function  deleteUser(e){
  setDeLoad(true);
  const delUser = {
    "uuid": selectedUser
  }
  const   response = await axios({ method: "post", url:base_url+"/admin/delete-staff-member", data: delUser, 'Content-Type': 'application/json', headers: {"Authorization" : `Bearer ${user}`} })
  //console.log(response)
  if(response.statusText === 'OK'){
    setDeLoad(false);
    setMsg(response.data.response);
    setpopup(false);
  }
 }



  return (
    
    <div className="Dashboard" >
      

     


      <div className="page-wrpper"> 
      <Header />
    <div className='content-wrapper' >
        <div className='header-area'>
         
          <div className='header-top'>
            <div className='left-content' >
            <h1> User's List </h1>
              
            </div>

            
            <div className='right-content'>
            { role ==='admin' ? <Link className='adminlogin' to={'/Adminlogin'} target="_blank">Database Admin</Link> : ''}
            <span className='current-user'><img src={ Userfront.user.image } />
           { Userfront.user.name }</span>
              <button onClick={ logout }>Logout</button>
              <a href='/useredit'>edit user</a>
            </div> 
          </div>
          
     
          <div className='header-left'>

           <Grid align="center" mb="md">
            <Grid.Col xs={8} sm={9}>
              <TextInput sx={{ flexBasis: '60%' }} placeholder="Search user by Name, Phone Number, Email Id" icon={<IconSearch size={16} />} value={query}  onChange={(e) => setQuery(e.currentTarget.value)} />
            </Grid.Col></Grid>
        </div>

        <div className='header-right'>

        <div className='filter item datefilter'>
          <div className="filter-title" ><DatePicker isClearable={true}  dateFormat="MMM dd, yyyy" selected={startDate} placeholderText={moment(new Date()).format("MMM DD, YYYY")}  onChange={(date) => {setStartDate(date);setToday('')}}  /> <IconCalendar size={20} /></div>
        </div>
      </div>
      
      </div>


      <div className='main-content'>

      {loaded === false ? <Loader /> : (

      <Box >
         {!msg == '' ? (<div className='msg-wrapper'>{msg}</div>) :''}
    <DataTable
      noHeader
      records={records}
      columns={[
        { accessor: 'firstname', cellsClassName: 'first-name', width: 90},
        {accessor: 'lastname', cellsClassName:'last-name', width: 90,},
        { accessor: 'mobile', render: ({ country_code, mobile_number }) => `${country_code} ${mobile_number}`, cellsClassName:'mobile', width: 150},
        {accessor: 'email', cellsClassName:'email', width: 200},
        {accessor: 'created', render: ({ created_at }) => dayjs.unix(created_at).format("DD MMM YYYY"), cellsClassName:'onboarding',width: 150 },
        {accessor: 'uuid', render: ({ uuid,firstname, lastname, role}) => (role ==='staff' ? <div className='cta-wrapper' onClick={(e) => { setname(firstname);setlastname(lastname); setSuser(uuid); handleSubmit(uuid); }}><IconTrash /> Remove</div>:''), cellsClassName:'removeuser',width: 100},
      ]}
      totalRecords={profileData.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
    />
</Box>
  )}



{ popopActive === true ? (
  <>
   
  <div className='poup-bg'></div>
<div className='popup delete-member'>
{delLoad === true ? <Loader></Loader> : ''}
  <div className='popup-titlebar'>
    <h3>Are You sure want to Delete <span className='highlighted'>{setName} {setLastname}</span> User</h3>
    <span className='close' onClick={(e)=>{ setpopup(false) }}></span>
  </div>
    <div className='ctas-wrapper'>
      <button onClick={()=> {deleteUser()}} >Delete</button>
      <button onClick={()=>{setpopup(false)}}>Cancel</button>
    </div>
</div>
</>
):''}   


</div>

    </div>

</div>


</div>

    
  );

}





export default Members;
