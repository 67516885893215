import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import Dashboard from './components/Dashboard/Dashboard';
import Loginpage from './components/Login/Login';
import User from './components/User/User';
import Useredit from './components/Useredit/Useredit';
import Resetpass from './components/Resetpass/Resetpass';
import Members from "./components/Members/Members";
import Groups from "./components/Groups/Groups";
import Coaches from "./components/Coaches/Coaches";
import Mygroups from "./components/Mygroups/Mygroups";
import MygroupsMembers from "./components/Mygroupsmembers/Mygroupsmembers";
import './App.css'
import Managemeal from "./components/Managemeal/Managemeal";
import Manageexercise from "./components/Manageexercise/Manageexercise";
import Loginadmin from "./components/Loginadmin/Loginadmin";
import Manageimages from "./components/Manageimages/Manageimages";



//Userfront.init("6nz9mqvb");


window.name = localStorage.getItem("user");


export default function App() {

  const [user, setUser] = useState(localStorage.getItem("user"));
  const [role, setrole] = useState(localStorage.getItem("userrole"))
  const [userAdmin, setUserAdmin] = useState(localStorage.getItem("userAdmin"));
  const [roleAdmin, setroleAdmin] = useState(localStorage.getItem("userroleAdmin"))
  const [current, setcurrent] =  useState();



useEffect(() => {
setUser(localStorage.getItem("user"));
setrole(localStorage.getItem("userrole"));
setUserAdmin(localStorage.getItem("userAdmin"));
setroleAdmin(localStorage.getItem("userroleAdmin"));

if (user && user !== 'undefined' && user !=='' && user !== null && role === 'admin') {
  setcurrent('adminuser')
} else if(user && user !== 'undefined' && user !=='' && user !== null && role === 'coach'){
  setcurrent('coachuser')
} 
//console.log("my--"+window.name);
}, [user, App, Route]);





  return (
    <Router>


      <div>
        <Routes>
        <Route path="/Adminlogin" element={  <RedirecttoAdmin><Loginadmin /></RedirecttoAdmin>} />
        <Route path="/Login" element={
             <Redirectto>
              <Loginpage />
              </Redirectto>
              }  />
          {roleAdmin==='Database Admin' ? <> 
            <Route path="/Managemeal" element={  <Managemeal />} />
            <Route path="/Manageexercise" element={ <Manageexercise /> } />
            <Route path="/Manageimages" element={ <Manageimages /> } />
          </>
          : ''}
          {current==='adminuser' ? <>
          <Route path="/Dashboard" element={ <Dashboard />} />
          <Route path="/Coaches" element={ <Coaches /> }/>
          <Route path="/Groups" element={ <Groups></Groups>} />
          <Route path="/Memberlist" element={  <MygroupsMembers />} />
          
          </>: ''}
          {current==='adminuser' || current==='coachuser' ? <>
          <Route path="/user/" element={  <User />} />
          </>: ''}
          {current==='coachuser' ? <>
          <Route path="/Mygroups" element={  <Mygroups />} />
          <Route path="/Memberlist" element={  <MygroupsMembers />} />
          </>: ''}
         
          <Route path="/resetpass" element={
            <Resetpass />
          }  />
          <Route path="/" element={
           
             <Loginpage />
            
          } />
          
        
        <Route
            path="/useredit"
            element={
              current==='adminuser' || current==='coachuser' ? 
                <Useredit />
             :''
            }
          />
        <Route
            path="/members"
            element={
              current==='adminuser' || current==='coachuser' ? 
                <Members />
              :''
            }
          />
          
        </Routes>
      </div>
    </Router>
  );
}




function RequireAuth({ children }) {
  let location = useLocation();
  const user = localStorage.getItem("user");
  //console.log(user);
  if (!user || user === 'undefined' || user ==='' || user === null   ) {
    return <Navigate to="/Login" state={{ from: location }} replace />;
  //  }
  }
  return children;
}

function Redirectto({ children }) {
  const user = localStorage.getItem("user");
  //console.log(user);
  let location = useLocation();
 if (user && user !== 'undefined' && user !=='' && user !==null) {
    return <Navigate to="/Dashboard" state={{ from: location }} replace />;
  }
  return children;
}


function RedirecttoAdmin({ children }) {
  const userAdmin = localStorage.getItem("userAdmin");
  //console.log(user);
  let location = useLocation();
 if (userAdmin && userAdmin !== 'undefined' && userAdmin !=='' && userAdmin !==null) {
    return <Navigate to="/Managemeal" state={{ from: location }} replace />;
  }
  return children;
}
