
import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../header';
import './dashboard.css';
import { Box, Grid, Checkbox, TextInput, NativeSelect} from '@mantine/core';
import { DataTable } from 'mantine-datatable';
/* import { useDebouncedValue } from '@mantine/hooks'; */
import { IconSearch, IconFilter, IconCalendar, IconArrowRight } from "@tabler/icons-react";
import Userfront from "@userfront/react";
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import userImage from '../../images/user.svg';


function Dashboard() {

  localStorage.setItem("DATE", (moment()).format('YYYY-MM-DD'));

const [profileData, setProfileData] = useState([]);
const [oldData, setoldData] = useState([]);
const [appData, setAppoData] = useState([]);
const [loaded, dataLoading] = useState(false);
const [loaded1, dataLoading1] = useState(false);
const user = localStorage.getItem("user")
const [numUsers, setNumUsers] = useState();
const userName = localStorage.getItem("userName");
const userRole = localStorage.getItem("userrole");
let now = dayjs();

const [startD, setStartD] = useState((moment(now.format("YYYY-MM-DD")).subtract(5, 'month').startOf('month').format('YYYY-MM-DD')))
const [endD, setEndD] = useState((moment(now.format("YYYY-MM-DD")).endOf('month').format('YYYY-MM-DD')))
//console.log(startD);


const userApp = {
  "start_date":startD,
  "end_date":endD
}

/* const num = 1; */

const [itemInPager, setItemInPager] = useState(25);
const [num1, setNum] = useState(1);
const base_url = process.env.REACT_APP_BASE_URL;
function getData(e, f){
  axios({ method: "GET", url:base_url+"/admin/user/get-all-users/"+e+"/"+f, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
      setProfileData(response.data.response);  setoldData(response.data.response); setNumUsers(response.data.count)
    // console.log(response.data.response)
      if(response.data.status===200){ dataLoading(true); }

  })
}

function getData1(){

    axios({ method: "POST", url:base_url+"/booking/get-appointments", data: userApp, crossDomain: true, headers: { "Authorization" : `Bearer ${ user }`},})
    .then((response) => { setAppoData(response.data.response); dataLoading1(true);
      //console.log(response.data.response)
   })

  }




const [neAppdata, setApp] = useState();
const [a, seta] = useState();
const updateState = () => {
  if(Array.isArray(appData)){
  const newState = appData.slice(0).reverse().map(obj => {
     // console.log(obj);
      if (obj.date) {
        return {date: moment(obj.date).format("YYYY-MM-DD"), id: obj.meeting_url, start: ''+moment(obj.date).format("YYYY-MM-DD")+'T'+obj.time+'', title: obj.subject };
        
      }
    return obj;
  });
  setApp(newState);

  
 
};



}  
//console.log(neAppdata)

let currenttime = now.format("MM-DD-YYYY");
/* let currenttime2 = now.format("YYYY-MM-DD"); */
  const PAGE_SIZE = 15;
 /*  const [page, setPage] = useState(1); */
  //const initialRecords = profileData.slice(0, PAGE_SIZE);
  const [records, setRecords] = useState(profileData.slice(0, PAGE_SIZE));
  const [query, setQuery] = useState('');
  const [maleUser, male] = useState(false);
  const [femaleUser, female] = useState(false);
  const [userGoal, goal] = useState(false);
  const [usergw, gw] = useState(false);
  const [bothgoal, setbothgw] = useState(false);
/*   const [debouncedQuery] = useDebouncedValue(query, 200); */
  const [today, setToday] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startDate1, setStartDate1] = useState('');

/* const [listCount, setCount] = useState('0'); */
const [switchData, setSwithdata] = useState('userlist');
const [srch, search] = useState(false);


const getarr =  { 'value': query, 'gender':[{'male': maleUser, 'female' :femaleUser}] ,'goal':[{'g':usergw, 'w': userGoal, 'gw': bothgoal}], 'date': startDate1 }
//console.log(getarr)

const searchResult = async (e) => {
    dataLoading(false)
   // search(false)
}

const [timer, setTimer] = useState(null)
const inputChanged = e => {
  clearTimeout(timer)
  const newTimer = setTimeout(() => {
   setQuery(e.target.value)
   dataLoading(false)
  }, 1000)
  setTimer(newTimer)
}

const [pagerVis, setPagerVis] = useState(true);
     useEffect(() => {
        if(query.length > 0 ||  maleUser === true || femaleUser===true || usergw === true || userGoal===true || bothgoal===true || startDate1!==''){
          setPagerVis(false)
          axios({ method: "POST", url:base_url+"/admin/user/user-search",  data:getarr,  crossDomain: true, headers: { "Authorization" : `Bearer ${ user }`},})
          .then((response) => { 
            if(response.data.response ==='No data found'){ setProfileData([])} 
            else{ setProfileData(response.data.response)}; dataLoading(true);
          //  search(false)
        })
      }else{
        dataLoading(false)
        setProfileData(oldData)
        if(profileData.length > 0){dataLoading(true);}
        setPagerVis(true)
      }
      if(loaded===false && loaded1===false && appData.length === 0) { getData(1, 25);getData1(); updateState();  }
      setRecords( profileData)
     // console.log(profileData)
      if(num1===null || num1==='' || num1==='undefined' || isNaN(num1)){
        setNum(1); getData1();updateState();
      }
  }, [query,maleUser,femaleUser,userGoal, num1, loaded,  startDate1, usergw, bothgoal, oldData]);

  const [isActive] = useState("");
  const toggleClass = (e) => {
    if(e.target.getAttribute("status")==='current'){
    e.target.removeAttribute("status", 'current');
    }else{
    e.target.setAttribute("status", 'current');
    }
  };


  const [userId] =  " "; 
  let location = useLocation();
  const handleSubmit = async (userId) => {
    <Navigate to="'/user/"  state={{ from: location }} replace/>
  };

  const navigate = useNavigate();
  const logout = (e) => {
    /* let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
    keysToRemove.forEach(k =>
      localStorage.removeItem(k)) */
      localStorage.clear();
    navigate('/Login');
  }

/*   const logoutforadmin = (e) => {
     let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
    keysToRemove.forEach(k =>
      localStorage.removeItem(k)) 
     navigate('/Adminlogin'); 
  }  */

 const [popopActive, setpopup] = useState(false);
const [popupData, setpopupData] = useState([]);
const getEventData = (e) => {
  setpopup(true);
  setpopupData({meeting_id: e.event.id, date: e.event.startStr, subject: e.event.title})
}

const pager = (e) => {
if(num1===null || num1==='' || num1==='undefined' || isNaN(num1)){
  setNum(1)
  getData(1, itemInPager)
  updateState()
  dataLoading(false)
}else{
  setNum((num1)+e)
  getData(num1+e, itemInPager)
  updateState()
  dataLoading(false)
}
}

const dateChange = (e) =>{
  search(true)
  if(e!=='' && e!==null){
    setStartDate1(moment(e).format("YYYY-MM-DD"));
  }else{
    setStartDate1('');
  }
}


function updatePager(e){
  
  const b = (((parseInt(numUsers))/e).toFixed());
  if(parseInt(numUsers)>b){
    seta(parseInt(b))
  }
  if(num1>b){
    setNum(parseInt(b))
  }
}

/* function pagecountSubmit(e) {
  console.log(e)
  console.log(num1+e, itemInPager)
  setItemInPager(e)
  if(num1===null || num1==='' || num1==='undefined' || isNaN(num1)){
    setNum(1)
    getData(1, itemInPager)
    updateState()
    dataLoading(false)
  }else{
    setNum((num1))
    getData(num1, itemInPager)
    updateState()
    dataLoading(false)
  }
  
} */


  return (
    
    <div className="Dashboard" >
      <div className="page-wrpper"> 
      <Header />
    <div className='content-wrapper' >
        <div className='header-area'>
         
          <div className='header-top'>
            <div className='left-content' >
            <h1> User's List </h1>
              <div className='wrapper' status={today ? "active" : null}>
             <button value="todayfilter" onClick={(e) => {setToday(''); setStartDate(''); setStartDate1(''); setSwithdata('userlist'); search(true); searchResult()}}>All</button>
             <button value="todayfilter" onClick={(e) => {setToday(currenttime); setStartDate('');setStartDate1(moment(currenttime).format('YYYY-MM-DD')); search(true);searchResult();setSwithdata('userlist')}}>Added Today</button>
            </div>

            <button value="Appointments" onClick={(e) => {setSwithdata('appointments'); updateState() }}>Appointments</button>
            </div>

            
            <div className='right-content'>
            <Link className='adminlogin' to={'/Adminlogin'} target="_blank">Database Admin</Link>
            <span className='current-user'><img alt="user" src={ userImage } />
           <span className='user-details'><span className='username'>{ userName }</span> <span className='userrole'>{ userRole }</span></span>
           
           <span className='user-popup'><button onClick={ logout }>Logout</button></span>
           </span>
              
             
            </div> 
          </div>
          
          {switchData === 'userlist' ?
          <>
          
          <div className='header-left'>

           <Grid align="center" mb="md">
            <Grid.Col xs={8} sm={9}>
              <TextInput sx={{ flexBasis: '60%' }} placeholder="Search user by Name, Phone Number, Email Id" icon={<IconSearch size={16} />}   onChange={(e) => {inputChanged(e)} } />
            </Grid.Col></Grid>
        </div>

        <div className='header-right'>
        <div className='filter item' status={isActive ? "active" : null}>
            <div className="filter-title"  onClick={toggleClass} >Filters <IconFilter onClick={toggleClass} size={16} /></div>
               <div className='dropdown-filters'>
                <div className='dropdown-item'>
                  <div className='collapsible-filter-title'   name="filter" onClick={toggleClass}>Gender</div>
                  <div className='collapsible' >
                    <Checkbox label="Male" checked={maleUser} onChange={(e) => (male(e.currentTarget.checked), searchResult(), search(true) )}  />
                    <Checkbox label="Female" checked={femaleUser} onChange={(e) => (female(e.currentTarget.checked), searchResult(), search(true))} />
                  </div>
                </div>
                <div className='dropdown-item'>
                  <div className='collapsible-filter-title'  onClick={toggleClass}>Goal</div>
                  <div className='collapsible'>
                    <Checkbox label="Glucose Control" checked={usergw} onChange={(e) => (gw(e.currentTarget.checked), searchResult(), search(true))} />
                    <Checkbox label="Weight Control" checked={userGoal}  onChange={(e) => (goal(e.currentTarget.checked), searchResult(),search(true))} />
                    <Checkbox label="Both" checked={bothgoal}  onChange={(e) => (setbothgw(e.currentTarget.checked), searchResult(),search(true))} />
                  </div>
                </div>
            </div>
        </div> 
        <div className='filter item datefilter'>
          <div className="filter-title" ><DatePicker  isClearable={true}  dateFormat="MMM dd, yyyy" selected={startDate} placeholderText={moment(new Date()).format("MMM DD, YYYY")}  onChange={(date) => {setStartDate(date);  setToday(''); dateChange(date); dataLoading(false)}}  /> <IconCalendar size={20} /></div>
        </div>
      </div>
      
      </> : ''}
      </div>


      <div className='main-content'>
      {switchData === 'userlist' ? (
      <>
      {loaded === false ? <Loader /> : (

      <Box >
    <DataTable
      noHeader
      records={records}
      columns={[
        { accessor: 'firstname', cellsClassName: 'first-name', width: 90},
        {accessor: 'lastname', cellsClassName:'last-name', width: 90,},
        { accessor: 'mobile', render: ({ country_code, mobile_number }) => `${country_code} ${mobile_number}`, cellsClassName:'mobile', width: 150},
        {accessor: 'email', cellsClassName:'email', width: 200},
        { accessor: 'dob', render: ({ dob }) => moment(dob).format("DD MMM YYYY"),  cellsClassName:'dob', width: 120},
        {accessor: 'gender', cellsClassName:'gender', width: 80},
        {accessor: 'height', cellsClassName:'height', width: 75},
        {accessor: 'weight', cellsClassName:'weight', width: 75},
        {accessor: 'goal', cellsClassName:'goal', width: 75},
        {accessor: 'group_name', cellsClassName:'group-name', width: 100},
        {accessor: 'device', cellsClassName:'device', width: 75},
        {accessor: 'last_active', cellsClassName:'last-active',render: ({ last_active }) => moment(last_active).format("DD MMM YYYY"), width: 100},
        {accessor: 'created', render: ({ created_at }) => dayjs.unix(created_at).format("DD MMM YYYY"), cellsClassName:'onboarding',width: 100 },
        {accessor: 'uuid', cellsClassName:'user-link', width: 200,  render: ({ uuid,  dob}) => (<div className='cta-wrapper'><Link to={'/user?user='+uuid} onClick={(e) => { handleSubmit(uuid) }}><IconArrowRight /></Link></div> )},

      ]}

    />

{ pagerVis === true  ? ( <>
<div className="mantine-ooem79 pager">
  <NativeSelect label="Members display per page" defaultValue={itemInPager} onChange={(e) => {  setItemInPager( e.currentTarget.value) 
  setNum(num1)
  getData(num1, e.currentTarget.value)
  updateState()
  if(parseInt(numUsers)>(parseInt(numUsers)/e.currentTarget.value).toFixed()){
    seta((parseInt(numUsers)/e.currentTarget.value).toFixed())
    
  }
  
  updatePager(e.currentTarget.value)
  dataLoading(false)}} data={['25', '50', '100']} />
  <div className="mantine-Group-root mantine-kn8vaf">
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-4clav0" onClick={()=> { pager(-1) }} data-disbaled={num1===1 ? 'disabled' : 'visible'}  type="button" ><svg width="calc(1.625rem / 2)" height="calc(1.625rem / 2)" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="mantine-2jxpqh"><path d="M7.219 8l3.3 3.3-.943.943L5.333 8l4.243-4.243.943.943-3.3 3.3z" fill="currentColor"></path></svg></button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg" onClick={()=> {pager(-1)}} data-hidden={num1===1 ? 'disabled' : 'visible'} type="button" >{num1-1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg active" type="button" >{num1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg" onClick={()=> {pager(1)}} data-hidden={num1===a ? 'disabled' : 'visible'}  type="button" >{num1+1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-4clav0" onClick={()=> {pager(1) }} data-disbaled={num1===a ? 'disabled' : 'visible'} type="button" ><svg width="calc(1.625rem / 2)" height="calc(1.625rem / 2)" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="mantine-2jxpqh"><path d="M8.781 8l-3.3-3.3.943-.943L10.667 8l-4.243 4.243-.943-.943 3.3-3.3z" fill="currentColor"></path></svg></button>
    </div>
    </div>
</>) :''}
</Box>
  )}
</>
  ): ''}

{switchData === 'appointments' ? (

<>
<FullCalendar
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth" events={neAppdata} 
        datesSet={(arg) => {
      //    setStartD(arg.start) //starting visible date
       //   setEndD(arg.end) //ending visible date
        }}

        //eventContent={renderEventContent}
        eventClick={ function(e){
        //  console.log('t')
        getEventData(e)
        }}
      />

{ popopActive === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup'>
  <div className='popup-titlebar'>
    <div className='date'>{ moment(popupData.date).format('DD MMM YYYY')} </div>
    <div className='time'>{ moment(popupData.date).format('h:mm A')} </div>
    <span className='close' onClick={(e)=>{ setpopup(false) }}></span>
  </div>
  <div className='subject'>
    <span className='label'>Appointment Subject</span>
    <div className='sub-text'>{popupData.subject}</div>
  </div>
  <div className='meeting-link'>
  <span className='label'>Meeting Link -</span>
    <a href={popupData.meeting_id} className='link-text' rel="noreferrer"  target="_blank">{popupData.meeting_id}</a>
  </div>
</div>
</>
):''}

</>
):''}

</div>

    </div>

</div>


</div>

    
  );

}





export default Dashboard;
