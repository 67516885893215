

import { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";
import { Navigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { TextInput, Select, MultiSelect } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import moment  from 'moment';
//let now = dayjs();
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip,  ResponsiveContainer, BarChart, Bar, ReferenceLine } from 'recharts';
import Userglucose from '../Userglucose/Userglucose';
/* import { Checkbox } from 'tabler-icons-react'; */

var dataa = [{ value: '1', label: 'Mon' },{ value: '2', label: 'Tue' },{ value: '3', label: 'Wed' },{ value: '4', label: 'Thu' },{ value: '5', label: 'Fri' },{ value: '6', label: 'Sat' },{ value: '7', label: 'Sun' }];


const base_url = process.env.REACT_APP_BASE_URL;
const DATE = localStorage.getItem("DATE");
function Resetpass() {


  const [fasting, setFasting] = useState([]);
  const [gmi, setGmi] = useState([]);
  const [range, setRange] = useState([]);
  const [gchart, setGChart] = useState([]);
  const [wchart, setwChart] = useState([]);
  const [rangeAve, setrangeAve] = useState([]);
  const [Extrarange, setExrtarange] = useState([]);
  const [rangeAveDay, setrangeAveDay] = useState([]);
  const [loaded, dataLoading] = useState(false);
  const [loaded1, dataLoading1] = useState(false);
  const [loaded2, dataLoading2] = useState(false);
  const [loaded3, dataLoading3] = useState(false);
  const [loaded4, dataLoading4] = useState(false);
  const [loaded5, dataLoading5] = useState(false);
  const [loaded6, dataLoading6] = useState(false);
  const [loaded7, dataLoading7] = useState(false);
  
  const queryParameters = new URLSearchParams(window.location.search);
  const UID = queryParameters.get("user");
  const user = localStorage.getItem("user").replace(/['"]+/g, '');
  
  const [startDay, setstartDay] = useState((moment(DATE).startOf('isoWeek').format('YYYY-MM-DD')));
  const [endDay, setenDay] = useState((moment(DATE).endOf('isoWeek').format('YYYY-MM-DD')));
  
  const [startDay2, setstartDay2] = useState((moment(DATE).startOf('isoWeek').format('YYYY-MM-DD')));
  const [endDay2, setenDay2] = useState((moment(DATE).endOf('isoWeek').format('YYYY-MM-DD')));
  
  const [startDay3, setstartDay3] = useState(moment(DATE).subtract(1, 'days').format('YYYY-MM-DD'));
  const [endDay3, setenDay3] = useState(moment(DATE).format('YYYY-MM-DD'));
  const [startDay1, setstartDay1] = useState(moment(DATE).subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'));
  const [endDay1, setenDay1] = useState(moment(DATE).endOf('isoWeek').format('YYYY-MM-DD'));
  const [startDay4, setstartDay4] = useState(moment(DATE).subtract(6, 'days').format('YYYY-MM-DD'));
  const [endDay4, setenDay4] = useState(moment(DATE).endOf('isoWeek').format('YYYY-MM-DD'));
  const [startDay5, setstartDay5] = useState(moment(DATE).subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'));
  const [endDay5, setenDay5] = useState(moment(DATE).endOf('isoWeek').format('YYYY-MM-DD'));
  const startDay6 = moment(DATE).format('YYYY-MM-DD');
  const [startDay7 , setstartDay7] = useState(moment(DATE).format('YYYY-MM-DD'));
  
  //console.log("startDay"+startDay+'---- endDay'+endDay+"----today"+toDay+"---yest"+yesterDay);
  
  const mealch = { "uuid": UID, "start_date":startDay, "end_date":endDay, "is_admin": 'True'}
  const mealch1 = { "uuid": UID, "start_date":startDay1, "end_date":endDay1, "is_admin": 'True'}
  const mealch2 = { "uuid": UID, "start_date":startDay2, "end_date":endDay2, "is_admin": 'True'}
  const mealch3 = { "uuid": UID, "start_date":startDay3, "end_date":endDay3, "is_admin": 'True'}
  const mealch4 = { "uuid": UID, "start_date":startDay4, "end_date":endDay4}
  const mealch5 = { "uuid": UID, "start_date":startDay5, "end_date":endDay5, 'is_one_day': false}
  const mealch6 = { "uuid": UID, "start_date":startDay, "end_date":endDay5, 'is_one_day': true}
  
  console.log(mealch3)
  const [initialapiload, setinitapiload] = useState(true)
  
  async function getData(){
        // user Basic info
  
  
       
  
       try{
        const response = await axios({ method: "post", url:base_url+"/graph/glucose-oneday-graph-data", data: mealch3, 'Content-Type': 'application/json',crossDomain: true, headers: {"Authorization" : `Bearer ${user}`} })
         if(response.data.response!=="Error while fetching data"){setGChart([response.data.response])};
        /*  if(response){dataLoading3(true);} */
     //    console.log(response)
        } 
         finally {dataLoading3(true); }; 
       
  } 
  
  
    const [fastArr, setfastArr] = useState([]);
    const [rangeArr, setrangArr] = useState([]);
    const [gchartArr, setGchartArr] = useState([]);
    const [wchartArr, setwchartArr] = useState([]);
    const [wchartArr1, setwchartArr1] = useState([]);
  
  //  console.log(gchartArr);
  //console.log(mealch4);
  const [intialLoad3, setinit3] = useState(false);
    const updateState0 = () => {
     // console.log('passed')
    // console.log(gchart[0].full_day_glucose)
      if(Array.isArray(gchart?.[0]?.latest_24hrs_data)){
     
    const newState = (gchart[0].latest_24hrs_data).slice(0).reverse().map(obj => {
         // console.log(obj)
         setinit3(true)
          if (obj.timestamp) {
            return {...obj, day: moment.utc(obj.timestamp).format("ddd"), time: moment.utc(obj.timestamp).format("hh:mm a")};
          }
        return obj;
      })
     setGchartArr(newState.sort(function (left, right) {
      return moment(left.timestamp).diff(moment(right.timestamp))
  }));
     
  
    };
    console.log(gchartArr)
  }
  
  console.log(gchart?.[0]?.latest_24hrs_data)
  
  
  

  
  
  

  
  
  
  
  const CustomizedDot = () => {
    
  
  };
  
  const [daysNav, setdayNav] = useState('0');
  const dayscroll = (val)=>{if(val=='yes'){setdayNav('1')} else{setdayNav('0')};}
  
  const [daysNav1, setdayNav1] = useState('0');
  const dayscroll1 = (val)=>{if(val=='yes'){setdayNav1('1')} else{setdayNav1('0')};}
  
  const defaultDate =  moment(startDay6, "YYYY-MM-DD").weekday();
  const [changedDay, setchangedDay] = useState(false);
  
  const [day, setDay]  =  useState((moment(DATE).weekday()));

  
  
  const onChangeval1 = (e) => {
    var a = parseInt(moment(startDay6).weekday())
    dataLoading6(false);
   // console.log(e+'---'+a)
  if(a<e){
    let current = (e-a)
    let createdDate = moment(startDay6);
    let expirationDate = moment(createdDate).add(current, 'd');
   // console.log(current+'--'+expirationDate.format());
    setstartDay7(expirationDate.format('YYYY-MM-DD'))
   
  }else{
    let current = (a-e)
    let createdDate = moment(startDay6);
    let expirationDate = moment(createdDate).add(-current, 'd');
   // console.log(current+'--'+expirationDate.format());
    setstartDay7(expirationDate.format('YYYY-MM-DD'))
   
  }
  
  }
  
  
  
    useEffect(() => {
      getData(); 
      
  
    // console.log(startDay7)
      

      updateState0()

    /*   if(changedDay===true){ update_avr_daily(startDay7)} */
      // console.log(intialLoad7+"--"+loaded7)
      
    //  console.log("laoding...")
  
    }, [fasting, gmi, gchart, wchart ]);
  

  
  
  
  
  
  
  
  
  
    return (
      
      <div className='col col-100'>
                    <h2>Glucose</h2>
                    <div className='col-group two-col'>
  
  
                    <div className='col-wrapper exercise full-width-blk'>
                    <div className='filter-wrapper'>
                      <div className='left-div'><div className='total-cal dark-text'>Glucose Chart  <span>mg/dl</span></div>
                        
                      </div>
                      <div className='right-div'>
                      
                      </div>
                    </div>
  
                        
             
             
                  { Array.isArray(gchartArr) ?
                   <>
    { loaded3 === false ?  <Loader /> : (
  
                
   
                  
  <ResponsiveContainer  width={1000} height={300}><LineChart  data={gchartArr} margin={{ top: 5, right: 30,  left: 20, bottom: 5, }} >
                          <CartesianGrid vertical={false} strokeDasharray="10 10" />
                          <ReferenceLine y={180}  stroke="#EC0909" label="180 mg/dl" />
                          <ReferenceLine y={70}  stroke="#6CF600" label="70 mg/dl" />
                         <XAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} padding={{ left: 20, right: 20 }} dataKey="time" minTickGap={10} tickCount='7' />
                         <YAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} domain={[50, 250]} minTickGap={50} tickCount='5' ticks={['50', '100', '150', '200', '250']} />
                         <Tooltip  />
                         <Line dot={<CustomizedDot />} allowDecimals={false} type="monotone" dataKey="blood_glucose_mg_per_dL" stroke="#0051A6" />
                       </LineChart></ResponsiveContainer>
  
                      
                     
                     )} 
                     <div className='chartlabel'>12 Hr Format</div>
                     </>
  
  
                    :''}
  
  
  
  
                 
                  </div>
  
                    
                    </div>
                  </div>
  
      
    );
  }



export default Resetpass;
